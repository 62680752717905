import React from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";

import { SECTION_SX } from "../styles/shared";

import Container from "../components/container";
import ExternalLink from "./external-link";
import { CONTACT_EMAIL_ADDRESS, GITHUB_ISSUES_LINK } from "../lib/contact";

const faqs = [
  {
    heading: "Can I track AWS status?",
    subheading: (
      <>
        Yes. With our innovative, easy-to-use dashboard, you can track all of
        your AWS services in real-time. If you only want to track certain
        regions, simply select them from the filtering options when adding new
        services.
      </>
    ),
  },
  {
    heading: "How will I know when a service goes down?",
    subheading: (
      <>
        You may configure Email and Slack notifications with customizable
        settings per service. Enable and disable alerts for each of your
        services as you see fit. StatusVista will let you know through your
        subscribed channels when outages occur.
      </>
    ),
  },
  {
    heading: "How much does it cost?",
    subheading: (
      <>
        That's the best part of StatusVista: it's free to use forever. Upgraded
        subscription plans are available for a reasonable monthly fee if your
        business requires additional features such as outage notifications.
      </>
    ),
  },
  {
    heading: "What if you don't support a service I need? Can you add it?",
    subheading: (
      <>
        Of course! Simply drop us a line via email or open up a GitHub issue and
        our specialists will be able to add your service.
      </>
    ),
  },
  {
    heading: "How do I get in touch?",
    subheading: (
      <>
        You can email us anytime at{" "}
        <ExternalLink
          text
          href={`mailto:${CONTACT_EMAIL_ADDRESS}`}
          sx={{ fontSize: "large" }}
        >
          {CONTACT_EMAIL_ADDRESS}
        </ExternalLink>{" "}
        or open up a{" "}
        <ExternalLink
          text
          href={GITHUB_ISSUES_LINK}
          target="_blank"
          sx={{ fontSize: "large" }}
        >
          GitHub issue
        </ExternalLink>
        . We look forward to hearing from you.
      </>
    ),
  },
];

function FAQItem({ heading, subheading }) {
  return (
    <Box>
      <Stack spacing={1}>
        <Stack direction="row" spacing={2}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 40,
              minWidth: 40,
              height: 40,
              minHiehgt: 40,
              backgroundColor: "info.light",
              borderRadius: "50%",
              color: "common.white",
            }}
          >
            <Typography variant="h6">Q</Typography>
          </Box>
          <Typography variant="h6">{heading}</Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 40,
              minWidth: 40,
              height: 40,
              minHiehgt: 40,
              backgroundColor: "success.light",
              borderRadius: "50%",
              color: "common.white",
            }}
          >
            <Typography variant="h6">A</Typography>
          </Box>
          <Typography
            fontSize="large"
            sx={{ maxWidth: "md", wordBreak: "break-word" }}
          >
            {subheading}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}

export default function FAQs() {
  return (
    <Box component="section" sx={{ ...SECTION_SX, pb: 10 }}>
      <Container sx={{ maxWidth: { md: "lg" } }}>
        <Typography
          variant="h4"
          fontWeight="fontWeightBold"
          sx={{ textAlign: "center", mb: 4 }}
        >
          Frequently asked questions
        </Typography>
        {faqs.map((faq, idx) => (
          <Box key={idx}>
            <FAQItem {...faq} />
            {idx < faqs.length - 1 && <Divider sx={{ my: 4 }} />}
          </Box>
        ))}
      </Container>
    </Box>
  );
}
