import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";

import Container from "./container";
import { SignupButtons } from "./signup-button";

export default function Cta() {
  return (
    <Box
      component="section"
      sx={{
        position: "relative",
        pt: 20,
        pb: 15,
        mb: 4,
        bgcolor: "secondary.main",
        "&:after": {
          content: "''",
          clipPath: "ellipse(75% 100% at top)",
          position: "absolute",
          top: "calc(100% - 110px)",
          left: 0,
          right: 0,
          height: 150,
          bgcolor: "secondary.main",
        },
      }}
    >
      <Container>
        <Grid container spacing={10} sx={{ position: "relative" }}>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Typography variant="h2" fontWeight="fontWeightBold" sx={{ mb: 2 }}>
              Ready to get started?
            </Typography>
            <Typography fontSize="large" sx={{ mb: 8 }}>
              Empower your development team today
            </Typography>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                display: { xs: "none", sm: "flex" },
                justifyContent: "center",
              }}
            >
              <SignupButtons />
            </Stack>
            <Stack spacing={1} sx={{ display: { xs: "flex", sm: "none" } }}>
              <SignupButtons />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
