import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";

import { CONTACT_EMAIL_ADDRESS, GITHUB_ISSUES_LINK } from "../lib/contact";
import ContactImage from "../images/contact.jpg";
import { HEADING_MOBILE_IMAGE_SX, SECTION_SX } from "../styles/shared";

import Container from "./container";
import ExternalLink from "./external-link";

function ContactHero({ sx = {} }) {
  return (
    <Box
      sx={{
        backgroundImage: `url(${ContactImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: 1,
        height: 300,
        ...sx,
      }}
    />
  );
}

function ContactMethod({ buttonLink, buttonText, heading, subheading }) {
  return (
    <Grid item xs={12} sm={6}>
      <Typography variant="h6">{heading}</Typography>
      <Typography color="text.secondary" sx={{ mb: { xs: 2, md: 4 } }}>
        {subheading}
      </Typography>
      <ExternalLink href={buttonLink} target="_blank">
        <Button variant="outlined">{buttonText}</Button>
      </ExternalLink>
    </Grid>
  );
}

export default function Contact() {
  return (
    <Box
      component="section"
      sx={{
        ...SECTION_SX,
      }}
    >
      <Container>
        <Grid container spacing={4} sx={{ mb: 8 }}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" fontWeight="fontWeightBold" sx={{ mb: 1 }}>
              Get in touch
            </Typography>
            <ContactHero sx={HEADING_MOBILE_IMAGE_SX} />
            <Typography sx={{ mb: 8 }}>
              Want to chat? Feel free to reach out any time over email or get
              involved on GitHub. We'd love to hear from you!
            </Typography>
            <Grid container spacing={4}>
              <ContactMethod
                buttonLink={`mailto:${CONTACT_EMAIL_ADDRESS}`}
                buttonText="Contact by email"
                heading="Email"
                subheading={CONTACT_EMAIL_ADDRESS}
              />
              <ContactMethod
                buttonLink={GITHUB_ISSUES_LINK}
                buttonText="Visit GitHub"
                heading="GitHub"
                subheading="github.com/statusvista"
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <ContactHero />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
