import React from "react";
import { Box, Typography } from "@mui/material";

import { ScrollProvider, useScrollContext } from "../contexts/scroll";

import Anchor from "../components/anchor";
import Contact from "../components/contact";
import Container from "../components/container";
import Cta from "../components/cta";
import FAQs from "../components/faqs";
import Footer from "../components/footer";
import Header from "../components/header";
import PageWrapper from "../components/page-wrapper";

const pageDescription =
  "The ultimate status dashboard for developers. With the status of everything you depend on in one place, you can monitor services faster and more efficiently than ever before.";
const pageTitle = "Contact us";

function Hero() {
  return (
    <Box
      component="section"
      sx={{
        bgcolor: "primary.main",
        color: "common.white",
        pt: { xs: 2, md: 15 },
        pb: { xs: 8, md: 20 },
      }}
    >
      <Container>
        <Typography
          component="h1"
          variant="h2"
          fontWeight="fontWeightBold"
          sx={{ mb: 2, maxWidth: "md" }}
        >
          Contact us
        </Typography>
        <Typography fontSize="larger" sx={{ maxWidth: "sm" }}>
          Located in beautiful Boulder, CO, our support team is on standby to
          answer your questions.
        </Typography>
      </Container>
    </Box>
  );
}

function ContactPage() {
  const { headerHeight, pageContentRef } = useScrollContext();

  return (
    <>
      <Header variant="primary" />
      <Hero />
      <Anchor ref={pageContentRef} offset={headerHeight} />
      <Anchor id="contact" offset={headerHeight} />
      <Box sx={{ pt: 4 }}>
        <Contact />
      </Box>
      <Anchor id="faqs" offset={headerHeight} />
      <FAQs />
      <Cta />
      <Footer />
    </>
  );
}

export default function Page() {
  return (
    <PageWrapper pageDescription={pageDescription} pageTitle={pageTitle}>
      <ScrollProvider>
        <ContactPage />
      </ScrollProvider>
    </PageWrapper>
  );
}
